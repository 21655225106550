import React, { useEffect, useRef, useState } from "react"
import { navigate } from "gatsby"

import Accordion, { AccordionEntry } from "../components/accordion"
import Layout from "../components/layout"
import VideoContainer from "../components/VideoContainer"
import NewsLetterSignUpForm from "../components/newsletter"
import SEO from "../components/seo"

const IndexPage = ({ ...props }) => {
  const hostRefMickeyArabic = useRef(null)
  const hostRefLidiaAmharic = useRef(null)
  const hostRefAbiGerman = useRef(null)
  const hostRefGabyGerman = useRef(null)
  const hostRefAnnalauraFrench = useRef(null)
  const hostRefMickeyGerman = useRef(null)
  const [activeEntry, setActiveEntry] = useState(null)
  const [tops, setTops] = useState({})
  const refs = {
    hostMickeyArabic: hostRefMickeyArabic,
    hostLidiaAmharic: hostRefLidiaAmharic,
    hostAbiGerman: hostRefAbiGerman,
    hostGabyGerman: hostRefGabyGerman,
    hostAnnalauraFrench: hostRefAnnalauraFrench,
    hostMickeyGerman: hostRefMickeyGerman,
  }
  const refOrder = [
    "hostMickeyArabic",
    "hostLidiaAmharic",
    "hostAbiGerman",
    "hostGabyGerman",
    "hostAnnalauraFrench",
    "hostMickeyGerman",
  ]

  useEffect(() => {
    const { top, height } = hostRefMickeyArabic.current.getBoundingClientRect()
    const tops = refOrder
      .slice(1)
      .map(key => [key, refs[key]])
      .reduce(
        (acc, [key, ref]) => ({
          ...acc,
          [key]: acc.running,
          running: acc.running + ref.current.getBoundingClientRect().height,
        }),
        { hostMickeyArabic: top, running: top + height }
      )
    setTops(tops)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const activePath = props.location.pathname.replace(/\/$/, "")
    if (!activePath.startsWith("/hosts/")) return
    let top = null
    // eslint-disable-next-line default-case
    switch (activePath) {
      case "/hosts/mickey-arabic":
        if (hostRefMickeyArabic.current) top = tops.hostMickeyArabic - 48
        setActiveEntry("/hosts/mickey-arabic")
        break
      case "/hosts/lidia-amharic":
        if (hostRefLidiaAmharic.current) top = tops.hostLidiaAmharic - 48
        setActiveEntry("/hosts/lidia-amharic")
        break
      case "/hosts/abi-german":
        if (hostRefAbiGerman.current) top = tops.hostAbiGerman - 48
        setActiveEntry("/hosts/abi-german")
        break
      case "/hosts/gaby-german":
        if (hostRefGabyGerman.current) top = tops.hostGabyGerman - 48
        setActiveEntry("/hosts/gaby-german")
        break
      case "/hosts/annalaura-french":
        if (hostRefAnnalauraFrench.current) top = tops.hostAnnalauraFrench - 48
        setActiveEntry("/hosts/annalaura-french")
        break
      case "/hosts/mickey-german":
        if (hostRefMickeyGerman.current) top = tops.hostMickeyGerman - 48
        setActiveEntry("/hosts/mickey-german")
        break
    }
    if (top) window.scrollTo({ top, behavior: "smooth" })
  }, [props.location.pathname, tops])

  const handleAccordionChange = active => {
    navigate(active, { replace: true })
  }

  return (
    <Layout>
      <SEO title="Home" />

      <div>
        connect:
        <ul style={{ listStyleType: "none", padding: 0 }}>
          <li style={{ display: "inline-block", marginRight: "1em" }}>
            <a href="https://www.youtube.com/c/thelernentotalkshow">youtube</a>{" "}
            +
          </li>
          <li style={{ display: "inline-block", marginRight: "1em" }}>
            <a href="https://www.facebook.com/lernentotalk/">facebook</a> +
          </li>
          <li style={{ display: "inline-block", marginRight: "1em" }}>
            <a href="https://twitter.com/lernentotalk">twitter</a> +
          </li>
          <li style={{ display: "inline-block", marginRight: "1em" }}>
            <a href="https://www.instagram.com/lernentotalk/">instagram</a>
          </li>
        </ul>
      </div>

      <VideoContainer>
        <iframe
          src="https://www.youtube-nocookie.com/embed/koe8Tz-Tz6E"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          title="Lernen To Talk Show – Video of Mickey learning Arabic"
        ></iframe>
      </VideoContainer>

      <dl>
        <dt>
          <em>lernen</em>
        </dt>
        <dd>
          / the German word for <q>learn</q>, <q>to learn</q>, or{" "}
          <q>learning</q>.<br />
          / Pronounced LAIR-nin
          <br />/ The show began in Germany. Hence the name, The (Learning) to
          Talk Show.
        </dd>
      </dl>
      <p>
        <a href="https://vimeo.com/68707719">This video</a> explains how the
        Lernen to Talk Show got started.
      </p>
      <p>
        Each episode of the Lernen to Talk Show is a snapshot into the host’s
        language learning journey. The videos are funny, approachable, and full
        of tiny cultural details that organically and unpredictably emerge when
        two people from different backgrounds come together.
      </p>
      <div>
        <p>watch</p>
        <Accordion active={activeEntry} onChange={handleAccordionChange}>
          {(activeEntry, updateActiveEntry) => (
            <>
              <AccordionEntry
                ref={hostRefMickeyArabic}
                link={"/hosts/mickey-arabic"}
                title="Mickey / arabic"
                active={activeEntry === "/hosts/mickey-arabic"}
                activate={() => updateActiveEntry("/hosts/mickey-arabic")}
              >
                <p>
                  Mickey’s first language is U.S. English.
                  <br />
                  He spent 16 weeks in Jordan learning Arabic. He filmed an
                  interview at least once per week.
                </p>
                <p>
                  Watch the episodes{" "}
                  <a href="https://www.youtube.com/playlist?list=PLF3lNEjv7avRhDMZWbwae20ViUp2UxkTb">
                    here:
                  </a>
                  <br />
                  Subtitles available in Arabic and English.
                  <br />
                  These videos were made possible in part by{" "}
                  <a href="https://www.youtube.com/watch?v=xfhRbyBMFaM">
                    Ali Baba Language Center
                  </a>
                  .
                </p>
              </AccordionEntry>
              <AccordionEntry
                ref={hostRefLidiaAmharic}
                link={"/hosts/lidia-amharic"}
                title="Lidia / język amharski"
                active={activeEntry === "/hosts/lidia-amharic"}
                activate={() => updateActiveEntry("/hosts/lidia-amharic")}
              >
                <p>
                  Lidia’s first language is Polish.
                  <br />
                  She spent time in Ethiopia learning Amharic.
                </p>
                <p>
                  Watch the episodes{" "}
                  <a href="https://www.youtube.com/playlist?list=PLF3lNEjv7avTwDBuWppNBzbqgOWYWGxeP">
                    here:
                  </a>
                  <br />
                  Subtitles available in Amharic, transliterated Amharic,
                  English, and some in Polish, too.
                  <br />
                  This release was made possible in part by funds raised in a{" "}
                  <a href="https://www.indiegogo.com/projects/the-lernen-to-talk-show-goes-global#/">
                    crowdfunding campaign
                  </a>
                  .
                </p>
              </AccordionEntry>
              <AccordionEntry
                ref={hostRefAbiGerman}
                link={"/hosts/abi-german"}
                title="Abi / alemán"
                active={activeEntry === "/hosts/abi-german"}
                activate={() => updateActiveEntry("/hosts/abi-german")}
              >
                <p>
                  Abi’s first language is Rioplatense Spanish.
                  <br />
                  She lives in Germany and is learning German.
                </p>
                <p>
                  Watch the episodes{" "}
                  <a href="https://www.youtube.com/playlist?list=PLF3lNEjv7avSxlqjKLwlF3yGpm7HHFl08">
                    here:
                  </a>
                  <br />
                  Subtitles available in German and Spanish.
                </p>
              </AccordionEntry>
              <AccordionEntry
                ref={hostRefGabyGerman}
                link={"/hosts/gaby-german"}
                title="Gaby / alemán"
                active={activeEntry === "/hosts/gaby-german"}
                activate={() => updateActiveEntry("/hosts/gaby-german")}
              >
                <p>
                  Gaby’s first language is Mexican Spanish.
                  <br />
                  She lives in Germany and is learning German.
                </p>
                <p>
                  Watch the episodes{" "}
                  <a href="https://www.youtube.com/playlist?list=PLF3lNEjv7avRezD9fh4GrKxVniSMZ_JPT">
                    here:
                  </a>
                  <br />
                  Subtitles available in German and Spanish.
                </p>
              </AccordionEntry>
              <AccordionEntry
                ref={hostRefAnnalauraFrench}
                link={"/hosts/annalaura-french"}
                title="Annalaura / Französisch"
                active={activeEntry === "/hosts/annalaura-french"}
                activate={() => updateActiveEntry("/hosts/annalaura-french")}
              >
                <p>
                  Annalaura’s first language is German.
                  <br />
                  She lived in Belgium for a while and learned French.
                </p>
                <p>
                  Watch the episodes{" "}
                  <a href="https://www.youtube.com/playlist?list=PLF3lNEjv7avQ2UInB_30zbeW886hvDrPy">
                    here:
                  </a>
                  <br />
                  Subtitles available in French and German and English.
                </p>
              </AccordionEntry>
              <AccordionEntry
                ref={hostRefMickeyGerman}
                link={"/hosts/mickey-german"}
                title="Mickey / German (how it all started)"
                active={activeEntry === "/hosts/mickey-german"}
                activate={() => updateActiveEntry("/hosts/mickey-german")}
              >
                <p>
                  Mickey’s first language is U.S. English (yes, the same
                  Mickey).
                  <br />
                  He lived in Germany for one year and is still learning German.
                </p>
                <p>
                  First, watch{" "}
                  <a href="https://vimeo.com/68707719">this video</a>.<br />
                  Then, watch the{" "}
                  <a href="https://www.youtube.com/playlist?list=PLF3lNEjv7avTzYQwXU4DssdwRvG35O7Ei">
                    episodes
                  </a>
                  .
                  <br />
                  Subtitles available in English. Some in German, too.
                  <br />
                  Visit the original{" "}
                  <a href="https://fourththing.wordpress.com/the-lernen-to-talk-show/">
                    blog
                  </a>
                  .
                </p>
              </AccordionEntry>
            </>
          )}
        </Accordion>
      </div>

      <div>
        <p>
          Lernen to Talk’s <strong>mission</strong>: to inspire more people to
          learn languages.
        </p>
        <p>
          Lernen to Talk’s <strong>vision</strong>: a world in which foreignness
          is embraced.
        </p>
        <p>
          The world would be a better place if everyone tried to learn a foreign
          language.
        </p>
        <p>
          The act of learning a foreign language instills in the learner a sense
          of humility that opens the mind to new perspectives and a sense of
          empathy that opens the heart to new peoples.
        </p>
        <p>
          The Lernen to Talk Show was started by Mickey Mangan as an attempt to
          prove to American high school students that anyone can learn a
          language, and that it’s the most fun in the world. Lernen to Talk is
          now seeking a path forward to produce more <q>seasons</q> of the show
          together with spirited language learners, creative collaborators, and
          inspired brands whose goals align with Lernen to Talk’s vision of a
          world in which foreignness is embraced. Wherever a language is being
          learned, a Lernen to Talk Show can be made!
        </p>
        <p>
          For collaboration inquiries, email{" "}
          <a href="mailto:hello@lernentotalk.com?subject=2019 update">
            hello@lernentotalk.com
          </a>
          .
        </p>
      </div>
      <NewsLetterSignUpForm />
    </Layout>
  )
}

export default IndexPage
