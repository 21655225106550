import React, { forwardRef, useRef, useState, useEffect } from "react"

export const AccordionEntry = forwardRef(
  ({ link, title, children, active = false, activate }, ref) => {
    const contentRef = useRef(null)
    const [height, setHeight] = useState(0)
    useEffect(() => {
      if (!contentRef.current) return
      setHeight(active ? contentRef.current.scrollHeight : 0)
    }, [active])
    const handleActivation = event => {
      event.preventDefault()
      activate()
    }
    return (
      <>
        <dt ref={ref} style={{ cursor: "pointer" }}>
          {link ? (
            <a href={link} onClick={handleActivation}>
              {active ? "-" : "+"} {title}
            </a>
          ) : (
            <span onClick={handleActivation} className="link">
              {active ? "-" : "+"} {title}
            </span>
          )}
        </dt>
        <dd
          ref={contentRef}
          style={{
            height: `${height}px`,
            overflow: "hidden",
            transition: "height .35s ease-out",
          }}
        >
          {children}
        </dd>
      </>
    )
  }
)

const Accordion = ({ children, active = null, onChange }) => {
  const [activeEntry, setActiveEntry] = useState(active)
  const updateActiveEntry = index => {
    setActiveEntry(index)
    if (onChange) onChange(index)
  }
  useEffect(() => setActiveEntry(active), [active])
  return <dl>{children(activeEntry, updateActiveEntry)}</dl>
}

export default Accordion
