import React from "react"

const VideoContainer = ({ children }) => (
  <div
    style={{
      width: "100%",
      paddingTop: "56.25%",
      position: "relative",
      margin: "3rem 0",
    }}
  >
    {React.cloneElement(children, {
      style: {
        position: "absolute",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        width: "100%",
        height: "100%",
      },
    })}
  </div>
)

export default VideoContainer
