import React, { useReducer, useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"

const INITIAL_STATE = {
  message: null,
  isLoading: false,
  hasError: false,
  wasSubscribed: false,
}

const newsletterReducer = (state, action) => {
  switch (action.type) {
    case "SIGN_UP_REQUEST":
      return { ...state, isLoading: true }
    case "SIGN_UP_SUCCESS":
      return {
        ...state,
        isLoading: false,
        wasSubscribed: true,
        hasError: false,
        message: action.message,
      }
    case "SIGN_UP_FAILURE":
      return {
        ...state,
        isLoading: false,
        wasSubscribed: false,
        hasError: true,
        message: action.message,
      }
    default:
      return state
  }
}

const Success = ({ message }) => (
  <>
    <p style={{ marginBottom: 24, color: "limegreen" }}>Sign up successful!</p>
    <div
      style={{ marginBottom: 24 }}
      dangerouslySetInnerHTML={{ __html: message }}
    />
  </>
)

const NewsLetterSignUpForm = () => {
  const [state, dispatch] = useReducer(newsletterReducer, INITIAL_STATE)
  const [email, setEmail] = useState("")
  const [touched, setTouched] = useState(false)
  const [isValid, setIsValid] = useState(false)

  const handleBlur = event => setTouched(true)
  const handleChange = event => {
    setEmail(event.target.value)
    setIsValid(event.target.checkValidity())
  }
  const handleSubmit = async event => {
    event.preventDefault()
    dispatch({ type: "SIGN_UP_REQUEST" })

    const response = await addToMailchimp(email)
    console.log("response", response)
    if (response.result === "success")
      dispatch({ type: "SIGN_UP_SUCCESS", message: response.msg })
    else if (response.result === "error")
      dispatch({ type: "SIGN_UP_FAILURE", message: response.msg })
  }

  if (state.wasSubscribed) return <Success message={state.message} />
  return (
    <>
      <h4>Sign up to receive occasional email updates:</h4>
      {state.hasError && (
        <>
          <p style={{ color: "firebrick", marginBottom: 24 }}>
            There was a problem with the sign up:
          </p>
          <div
            style={{ marginBottom: 24 }}
            dangerouslySetInnerHTML={{ __html: state.message }}
          />
        </>
      )}
      <form
        onSubmit={handleSubmit}
        className="newsletter-form"
        style={{ marginBottom: 48, marginTop: 24 }}
      >
        <input
          type="email"
          name="EMAIL"
          value={email}
          onBlur={handleBlur}
          onChange={handleChange}
          className={`${touched && "touched"}`}
          disabled={state.isLoading}
          required
        />
        <button type="submit" disabled={!isValid || state.isLoading}>
          Subscribe
        </button>
      </form>
      {state.isLoading && <p>Subscribing …</p>}
    </>
  )
}

export default NewsLetterSignUpForm
